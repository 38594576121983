<template>
  <div>
    <el-drawer
      title="Upload common file"
      v-model="showAdd"
      direction="rtl"
    >
      <supplier-hub-file-form
        @update="getFiles()"
        :common="true"
        :pin_notice_id="pin_notice_id"
        :itt_framework_id="itt_framework_id"
      ></supplier-hub-file-form>
    </el-drawer>

    <div style="text-align: left;" class="row" v-if="itt_framework">
      <div class="col-md-12">
        <div class="card my-3">
          <div class="card-body px-0 pb-2">
            <div class="px-4">
              <h4>Essential Framework Files</h4>
              <div v-html="itt_framework.common_description"></div>
              <!-- <p class="text-wrap">
                Here are the essential documents for the CPRAS Procurement
                Framework Tender. Please complete the Supplier Input spreadsheet
                and SQ questions. These documents must then be uploaded into the
                'My File Library' section. Submissions will not be evaluated
                until the Response deadline has expired. Until that time,
                uploaded files can be removed and replaced at any time.
              </p>
              <p class="text-wrap">
                Any questions should be submitted via the My Questions section
                below. For technical difficulties, please email
                <a class="text-bold" href="mailto:supplier.support@cpras.co.uk"
                  >supplier.support@cpras.co.uk.</a
                >
              </p> -->
            </div>
            <div class="row">
              <!-- <div class="col-md-3">
                
              </div> -->
              <div class="col-md-12">
                <nav
                  class="navbar shadow-none my-3 navbar-transparent float-end"
                >
                
                  <el-form
                    :inline="true"
                    class="demo-form-inline"
                    @submit.prevent="getFiles($event)"
                  >
                    <!-- <el-form-item>
                      <el-input
                        v-model="term"
                        placeholder="Search File"
                        size="small"
                        v-allowed="'common_files, delete'"
                      ></el-input>
                    </el-form-item>
                    <el-form-item>
                      <el-button
                        v-allowed="'common_files, delete'"
                        type="primary"
                        @click="getFiles()"
                        size="small"
                        
                        >Search</el-button
                      >
                    </el-form-item> -->
                    <el-button-group>
                      <!-- <el-form-item> -->
                        <el-button 
                          v-tooltip="'Download'"
                          type="primary"
                          size="small"
                          @click="downloadAll()"
                          v-if="countSelectedDownloads()
                          &&
                          ((itt_framework.is_deadline_passed && $store.state.isUserAnOriginator() && !itt_framework.is_archived) ||
                          (!itt_framework.is_deadline_passed && !itt_framework.is_archived)
                          )
                          "
                          plain
                        >
                          <i class="fa-solid fa-download text-secondary"></i>
                          Download selected file{{
                            countSelectedDownloads() > 1 ? 's' : ''
                          }}
                          {{ countSelectedDownloads() }}</el-button
                        >
                      <!-- </el-form-item>
                      <el-form-item> -->
                        <el-button
                          v-if="
                              (itt_framework.is_deadline_passed && $store.state.isUserAnOriginator() && !itt_framework.is_archived) ||
                              (!itt_framework.is_deadline_passed && !itt_framework.is_archived)
                          "
                          type="primary"
                          @click="create()"
                          size="small"
                          v-allowed="'common_files, create'"
                          >Add</el-button
                        >
                      <!-- </el-form-item> -->
                    </el-button-group>
                  </el-form>
                </nav>
              </div>
            </div>
            <div class="table-responsive">
              <div style="text-align: right;padding-right: 20px;"></div>
              <table class="table table-fluid align-items-center mb-0">
                <thead>
                  <tr>
                    <th style="width: 10px;">
                      <el-checkbox
                        v-tooltip="'Select All'"
                        :checked="select_all"
                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                        @change="handleSelectAll($event)"
                      ></el-checkbox>
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      File Name
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="file in files" :key="file.id">
                    <td class="text-sm">
                      <el-checkbox
                        :checked="isSelected(file)"
                        @change="selectToDownload($event, file)"
                        v-model="file.selected"
                      ></el-checkbox>
                    </td>
                    <td class="text-sm">{{ file.file_name }}</td>
                    <td class="text-sm" style="width: 1%">
                      <a
                        class="link me-3 "
                        @click="downloadSingleFile(file.file.storage_path)"
                        v-if="file.file && 
                        ((itt_framework.is_deadline_passed && $store.state.isUserAnOriginator() && !itt_framework.is_archived) ||
                              (!itt_framework.is_deadline_passed && !itt_framework.is_archived)
                              )
                        "
                      v-tooltip="'Download'">
                        <i
                          class="fa fa-solid fa-download text-secondary"
                          
                        ></i>
                      </a>
                      <a
                        class="link me-3 "
                        v-allowed="'common_files, delete'"
                        v-if="(itt_framework && !itt_framework.is_archived) ||
                          !itt_framework
                          "
                        @click="remove(file)"
                        v-tooltip="'Delete'">
                        <i
                          class="fa-solid fa-trash text-danger"
                          
                        ></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-footer pt-0">
            <pagination :meta="meta" v-on:update="getFiles()" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SupplierHubFile } from '@/api_services/supplierhub_file'
import SupplierHubFileForm from './SupplierHubFileForm.vue'
import Swal from 'sweetalert2'

export default {
  components: { SupplierHubFileForm },
  props: {
    itt_framework: {
      type: Object,
      required: false,
      default: null
    },
    if_pin_notice: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data() {
    return {
      company_ids: [],
      itt_framework_id: null,
      pin_notice_id: null,
      files: [],
      term: null,
      meta: {
        page: 1,
        total: 0,
        pages: 1
      },
      showAdd: false,
      download_files: [],
      select_all: false
    }
  },
  computed: {
    this_user() {
      return this.$store.state.savedUser 
    }
  },
  created: function() {
    if(this.if_pin_notice == 1){
      this.pin_notice_id = this.itt_framework?.id
    }
    else{
      this.itt_framework_id = this.itt_framework?.id
    }
    this.getFiles()

    for (const [key, value] of Object.entries(this.this_user.companies)) {
      this.company_ids.push(value.id)
    }
  },
  methods: {
    checkIfNotJoined(){
      if(
        !this.itt_framework.if_user_joined && 
        this.itt_framework?.user?.id != this.this_user.id &&
        this.this_user?.company?.id != this.itt_framework?.company?.id &&
        !this.company_ids.includes(this.itt_framework?.company?.id)
      ){
        return true
      }
      return false
    },
    downloadSingleFile(file){
      let type = "itt"
      if (this.if_pin_notice == 1) {
        type = "pin"
      }

      if(this.checkIfNotJoined()){
        Swal.fire({
          title: 'Warning!',
          text: "You need to join this " + type + " in order to download this file!",
          icon: 'warning',
        })
        return false
      }

      window.location.assign(file)
    },
    handleSelectAll() {
      let self = this
      this.select_all = this.select_all ? false : true
      if (!this.select_all) {
        this.download_files = []
        this.files.forEach(function(file, i) {
          file.selected = false
          self.files[i].selected = false
        })
      } else {
        this.files.forEach(function(file, i) {
          file.selected = true
          self.files[i].selected = true
          self.download_files.push(file)
        })
      }
    },
    countSelectedDownloads() {
      let count = 0
      this.download_files.forEach(function(download) {
        if (download.selected) count += 1
      })

      return count
    },
    selectToDownload(value, file) {
      let selected = null
      let index = null
      this.download_files.forEach(function(download, i) {
        if (file.id == download.id) {
          selected = download
          index = i
        }
      })

      if (index != null) {
        this.download_files.splice(index, 1)
      }

      if (!selected) {
        file.selected = value
        this.download_files.push(file)
      }
    },
    isSelected(file) {
      let found = false
      this.download_files.forEach(function(download) {
        if (file.id == download.id) {
          if (download.selected) found = true
        }
      })
      return found
    },
    remove(file) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You are about to delete this file. This action cannot be undone!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          
          let type = "itt"
          if(this.if_pin_notice == 1){
            type = "pin"
          }

          SupplierHubFile.delete({ id: file.id, type: type })
            .then(() => {
              this.$toast.success('Removed successfully')
              this.getFiles()
            })
            .catch(error => {
              // Handle error accordingly
              console.error('error:', error.response.data)
              this.$toast.error('An error occurred while removing the file')
            })
        }
      })
    },
    create() {
      let type = "itt"
      if (this.if_pin_notice == 1) {
        type = "pin"
      }

      if(this.checkIfNotJoined()){
        Swal.fire({
          title: 'Warning!',
          text: "You need to join this " + type + " in order to add a file!",
          icon: 'warning',
        })
        return false
      }

      this.showAdd = true
    },
    search() {
      this.meta.page = 1
      this.getFiles()
    },
    downloadAll() {
      let type = "itt"
      if (this.if_pin_notice == 1) {
        type = "pin"
      }

      if(this.checkIfNotJoined()){
        Swal.fire({
          title: 'Warning!',
          text: "You need to join this " + type + " in order to download these files!",
          icon: 'warning',
        })
        return false
      }

      let ids = []
      this.$loading = true
      this.download_files.forEach(function(file) {
        if (file.selected) ids.push(file.id)
      })
      SupplierHubFile.downloadFiles({ params: { ids: ids } })
        .then(result => {
          const link = document.createElement('a')
          link.href = result.data.file

          // Set the file name that will be downloaded
          link.setAttribute('download', 'file-name-to-download.zip') // Replace with the desired file name

          // Hide the link
          link.style.display = 'none'

          // Append the link to the document body
          document.body.appendChild(link)

          // Simulate a click on the link to trigger the download
          link.click()

          // Remove the link from the DOM
          document.body.removeChild(link)
        })
        .catch(error => {})
        .finally(() => {
          this.$loading = false
        })
    },
    getFiles() {
      this.showAdd = false
      SupplierHubFile.get({
        params: { 
          page: this.meta.page, 
          common: true, 
          term: this.term,  
          itt_framework_id: this.itt_framework?.id,
          if_pin_notice: this.if_pin_notice
        }
      })
        .then(result => {
          if (result.data.files) this.files = result.data.files
          this.meta = result.data.meta
        })
        .catch(error => {
          this.$toast.error('An error occured while fetching common files')
          console.error('error:', error.response.data)
        })
    }
  }
}
</script>

<style scoped>
.completed {
  background: #d3ffe3;
}
</style>
