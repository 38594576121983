<template>
  <div>
    <el-drawer title="" v-model="showAdd" direction="rtl" :destroy-on-close="true" :append-to-body="true">
      <supplierhub-lot-form @close="getLots()" :lot="selected" :pin_notice_id="pin_notice_id"
        :itt_framework_id="itt_framework_id" :if_pin_notice="if_pin_notice"></supplierhub-lot-form>
    </el-drawer>

    <el-drawer title="" v-model="showView" direction="rtl" size="70%" :destroy-on-close="true" :append-to-body="true">
      <supplierhub-lot-view @close="getLots()" :lot="selected" :itt_framework="itt_framework"
        :pin_notice_id="pin_notice_id" :itt_framework_id="itt_framework_id" :if_pin_notice="if_pin_notice" :kind="kind"
        v-if="selected && showView"></supplierhub-lot-view>
    </el-drawer>

    <div style="text-align: left;" class="row" v-if="itt_framework">
      <div class="col-md-12">
        <div class=" my-3">
          <div class="card-body px-0 pb-2">
            <div class="px-4">
              <h4>Framework Lots</h4>
              <div v-html="itt_framework.spec_description"></div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <el-button plain type="primary" @click="getLots()" size="small"
                  style="margin-left: 10px;">Refresh</el-button>
              </div>
              <div class="col-md-12">
                <nav class="navbar shadow-none my-3 navbar-transparent float-end">
                  <el-form :inline="true" class="demo-form-inline" @submit.prevent="getLots($event)">
                    <el-form-item>
                      <el-button type="primary" @click="create()" size="small" v-if="allowCreate()"><i
                          class="fa-solid fa-plus"></i>
                        Create Lot
                      </el-button>
                    </el-form-item>
                  </el-form>
                </nav>
              </div>
            </div>

            <div class="table-responsive" style="max-height: 500px;">
              <el-table class="table-striped" header-row-class-name="text-primary" :data="lots" style="width: 100%">
                <el-table-column :min-width="120" class-name="td-actions" label="Name">
                  <template v-slot="props">
                    {{ props.row.name }}
                  </template>
                </el-table-column>
                <el-table-column :min-width="120" class-name="td-actions" label="Action">
                  <template v-slot="props">
                    <el-tooltip :enterable="false" placement="top-start">
                      <template #content>
                        <span>Specifications</span>
                      </template>
                      <el-button size="small" type="primary"
                        @click="handleView(props.row, 0)">Specifications</el-button>
                    </el-tooltip>

                    <el-tooltip :enterable="false" placement="top-start">
                      <template #content>
                        <span>Social Values</span>
                      </template>
                      <el-button size="small" type="warning" @click="handleView(props.row, 1)">Social Values</el-button>

                    </el-tooltip>

                    <el-tooltip :enterable="false" placement="top-start" v-if="allowCreate() ">
                      <template #content>
                        <span>Edit</span>
                      </template>
                      <el-button v-if="allowCreate()" size="small" @click="handleEdit(props.row)">
                        <i class="fas fa-pencil"></i>
                      </el-button>
                    </el-tooltip>

                    <el-tooltip :enterable="false" placement="top-start" v-if="allowCreate() ">
                      <template #content>
                        <span>Delete</span>
                      </template>

                      <el-button v-if="allowCreate()" size="small" type="danger" @click="handleDelete(lot)"><i
                          class="fas fa-trash"></i></el-button>
                    </el-tooltip>

                  </template>
                </el-table-column>
              </el-table>
              <br>
              <pagination :meta="meta" v-on:update="getLots()" />

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SupplierhubLot } from '@/api_services/supplierhub_lot'
import { mapState } from 'vuex'
import SupplierhubLotForm from './SupplierhubLotForm.vue'
import SupplierhubLotView from './SupplierhubLotView.vue'
import Swal from 'sweetalert2'

export default {
  components: { SupplierhubLotForm, SupplierhubLotView },
  props: {
    itt_framework: {
      type: Object,
      required: false,
      default: null
    },
    if_pin_notice: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data() {
    return {
      company_ids: [],
      itt_framework_id: null,
      pin_notice_id: null,
      showAdd: false,
      showImport: false,
      lots: [],
      term: null,
      status: 1,
      loadung: false,
      selected: null,
      showView: false,
      meta: {
        page: 1,
        total: 0,
        pages: 1
      },
      kind: 0
    }
  },
  watch: {
    status: function() {
      this.getLots()
    }
  },
  computed: {
    ...mapState(['savedUser']),
    this_user() {
      return this.$store.state.savedUser 
    }
  },
  created: function() {
    if(this.if_pin_notice == 1){
      this.pin_notice_id = this.itt_framework?.id
    }
    else{
      this.itt_framework_id = this.itt_framework?.id
    }

    this.status = this.savedUser.is_supplier_hub_admin ? 1 : 0
    this.getLots()

    for (const [key, value] of Object.entries(this.this_user.companies)) {
      this.company_ids.push(value.id)
    }
    console.log(this.itt_framework)
  },
  methods: {
    allowCreate(){

      let condition = (this.itt_framework.is_deadline_passed && this.$store.state.isUserAnOriginator() && !this.itt_framework.is_archived) ||
        ((!this.itt_framework.is_deadline_passed && !this.itt_framework.is_archived && this.savedUser.is_supplier_hub_admin) &&
          (this.$store.state.isUserASupplier() && this.if_pin_notice == 1) || (!this.$store.state.isUserASupplier())
      )
      if (this.itt_framework && this.if_pin_notice) {
        if (this.itt_framework.read_only_from_status) return false
      }
      return condition
    },
    checkIfNotJoined(){
      if(
        !this.itt_framework.if_user_joined && 
        this.itt_framework?.user?.id != this.this_user.id &&
        this.this_user?.company?.id != this.itt_framework?.company?.id &&
        !this.company_ids.includes(this.itt_framework?.company?.id)
      ){
        return true
      }
      return false
    },
    handleDelete(lot){
      this.loading = true

      let type = "itt"
      if(this.if_pin_notice == 1){
        type = "pin"
      }

      if(this.checkIfNotJoined()){
        Swal.fire({
          title: 'Warning!',
          text: "You need to join this " + type + " in order to delete this lot!",
          icon: 'warning',
        })
        return false
      }

      Swal.fire({
        title: 'Are you sure?',
        text: 'You are about to delete this data. This action cannot be undone!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          SupplierhubLot.delete(lot.id, type)
          .then(resp => {
            this.getLots()
            this.$toast.success(resp?.data?.message || 'Framework Lot has been removed successfully')
          })
          .catch(error => {
            this.$toast.error('An error occured while deleting invoice')
            console.error('error:', error.response.data)
          })
          .finally(() => {
            this.loading = false
          })
        }
      })
    },
    handleView(lot, kind) {
      this.selected = Object.assign({}, lot)
      this.showView = true
      this.kind = kind
    },
    handleEdit(lot) {
      let type = "itt"
      if (this.if_pin_notice == 1) {
        type = "pin"
      }

      if(this.checkIfNotJoined()){
        Swal.fire({
          title: 'Warning!',
          text: "You need to join this " + type + " in order to edit this lot!",
          icon: 'warning',
        })
        return false
      }

      this.selected = Object.assign({}, lot)
      this.showAdd = true
    },
    create() {
      let type = "itt"
      if (this.if_pin_notice == 1) {
        type = "pin"
      }

      if(this.checkIfNotJoined()){
        Swal.fire({
          title: 'Warning!',
          text: "You need to join this " + type + " in order to add a lot!",
          icon: 'warning',
        })
        return false
      }

      this.showAdd = true
    },
    search() {
      this.meta.page = 1
      this.getLots()
    },
    getLots() {
      this.showAdd = false
      let params = {
        page: this.meta.page,
        term: this.term,
        itt_framework_id: this.itt_framework?.id,
        if_pin_notice: this.if_pin_notice
      }
      this.loading = true
      SupplierhubLot.get({
        params: params
      })
        .then(result => {
          if (result.data.lots) this.lots = result.data.lots
          this.meta = result.data.meta
          this.loading = false
        })
        .catch(error => {
          this.$toast.error('An error occured while fetching lots')
          console.error('error:', error.response.data)
        })
        .finally(() => {})
    }
  }
}
</script>

<style scoped>
.completed {
  background: #d3ffe3;
}
</style>
