<template>
  <loading-component :loading="loading" :message="loading_title_message"
    :content="loading_content_message"></loading-component>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <h5>Pin Notices</h5>
            <div class="pb-0 card-header">
              <div class="row">
                <div class="col-md-2 mb-2">
                  <el-input v-model="search_value" placeholder="Search" class="input-with-select">
                    <template #append>
                      <el-button><i class="fa fa-search"></i></el-button>
                    </template>
                  </el-input>
                </div>
                <div class="col-md-5 mb-2">
                  <el-segmented v-if="!$store.state.isUserASupplier()" @change="filterChange" v-model="selected_filter"
                    :options="filter_options" />
                </div>
                <div class="col-md-5 mb-2" style="text-align: right;">
                  <button v-if="$store.state.isUserAnOriginator() || $store.state.isAdmin()" type="button" class="btn btn-success btn-sm mb-2 me-2"
                   @click="openContractAuthorityModal">
                    <i class="fa-solid fa-user-plus me-1"></i> Additional Contracting Authority
                    <el-badge :value="pending_contract_authority_count" class="correspondent_notification" :hidden="pending_contract_authority_count == 0" >
                      <i class="cursor-pointer fa fa-lists "></i>
                    </el-badge>
                  </button>
                  <button
                    v-if="$store.state.isUserABuyer() || $store.state.isUserAnOriginator() || $store.state.isAdmin()"
                    type="button" class="btn btn-info btn-sm mb-2 me-2" @click="openInvite(1)">
                    <i class="fa-solid fa-user-plus me-1"></i> Invite External Users
                  </button>
                  <button
                    v-if="$store.state.isUserABuyer() || $store.state.isUserAnOriginator() || $store.state.isAdmin()"
                    type="button" class="btn btn-primary btn-sm mb-2 me-2" @click="openInvite(0)">
                    <i class="fa-solid fa-user-plus me-1"></i> Invite Participants
                  </button>
                  <button type="button" class="btn bg-gradient-secondary btn-sm mb-2 me-2"
                    @click="loadPinNotices('list')">
                    <i class="fa-solid fa-arrow-rotate-right me-1"></i> Reload
                  </button>
                </div>
              </div>
              <div class="text-end">
              </div>
            </div>
            <br>
            <div class="table-responsive">
              <table class="table table-fluid align-items-center mb-0 table-hover ">
                <thead>
                  <tr>

                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Project
                    </th>
                    <th v-if="$store.state.isUserASupplier()"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Buyer
                    </th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Date
                    </th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      
                    </th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      PIN Status
                    </th>
                    <th v-if="$store.state.isUserABuyer() || $store.state.isUserAnOriginator()"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Project Status
                    </th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Deadline
                    </th>

                    <th v-if="!$store.state.isUserASupplier() && selected_filter != ProjectFilters.FILTER_MY"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Creator
                    </th>
                    <th v-if="selected_filter != ProjectFilters.FILTER_MY"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Company
                    </th>

                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!pin_notices.length">
                    <td v-if="loading" colspan="100%" class="text-sm">
                      <i class="fas fa-cog fa-spin me-2"></i>Loading...
                    </td>
                    <td v-else colspan="100%">No data</td>
                  </tr>
                  <tr v-else class="text-sm" v-for="pin_notice in pin_notices" :key="pin_notice.id">

                    <td>{{ pin_notice.name }}</td>
                    <td v-if="$store.state.isUserASupplier()">{{ pin_notice.user.name }}</td>
                    <td>{{ $filters.dateFormatFilter(pin_notice.created_at) }}</td>
                    <td>
                      <el-tag size="small" class="mb-2" v-if="pin_notice.if_user_joined" type="success" effect="dark">Joined</el-tag>
                      <el-tag size="small" class="mb-2" v-if="!pin_notice.if_user_joined" type="info" effect="dark">Not Joined</el-tag>

                      <template v-if="$store.state.isUserABuyer() || $store.state.isUserAnOriginator()">
                        <br>
                        <template v-if="pin_notice.is_user_a_contracting_authority">
                          <el-tag size="small" class="pb-3 pt-3" v-if="pin_notice.contracting_authority_status == 1" type="success" effect="dark">Contracting<br>Authority</el-tag>
                          <el-tag size="small" class="pb-4 pt-4" v-if="pin_notice.contracting_authority_status == 0" type="warning" effect="light">Pending<br>Contracting<br>Authority</el-tag>
                        </template>
                        <!--<el-tag size="small" class="pb-3 pt-3" v-if="!pin_notice.is_user_a_contracting_authority" type="info" effect="light">Non-Contracting<br>Authority</el-tag>-->
                      </template>
                    </td>
                    <td>
                      <template
                        v-if="pin_notice.project_status != ProcurementProjectStatuses.CODE_DOC_FINALIZE && pin_notice.project_status != ProcurementProjectStatuses.CODE_ITT_PENDING">
                        <el-tag size="small" type="warning" v-if="pin_notice.pin_status == 'Pending'" effect="light">{{
                          pin_notice.pin_status }}</el-tag>
                        <el-tag size="small" type="success" v-if="pin_notice.pin_status == 'Active'" effect="dark">{{
                          pin_notice.pin_status }}</el-tag>
                        <el-tag size="small" type="info" v-if="pin_notice.pin_status == 'Archived'" effect="dark">{{
                          pin_notice.pin_status }}</el-tag>

                        <el-tag size="small" class="ms-1" type="warning"
                          v-if="(pin_notice.if_user_joined && pin_notice.if_user_joined.pin_leave_request == 1)"
                          effect="dark">Waiting for Leave Request Approval</el-tag>

                      </template>
                      <template v-else>
                        <el-tag v-tooltip="'Document Finalization'" :type="'info'" :effect="'light'">Document
                          Finalization</el-tag>
                      </template>

                    </td>
                    <td v-if="$store.state.isUserABuyer() || $store.state.isUserAnOriginator()">
                      <el-tag v-tooltip="pin_notice.project_status_label" :type="pin_notice.project_status_tag.color"
                        :effect="pin_notice.project_status_tag.type">{{ pin_notice.project_status_label }}</el-tag>
                    </td>
                    <td class="text-wrap text-xs">{{ pin_notice.deadline ?
                      ($filters.dateFormatFilter(pin_notice.deadline) + (pin_notice.is_deadline_passed == 1 ? "(Passed)"
                      : "") ) : "No deadline yet" }}</td>

                    <td :class="pin_notice?.user?.id == user.id ? 'text-info text-bold' : ''"
                      v-if="!$store.state.isUserASupplier() && selected_filter != ProjectFilters.FILTER_MY"
                      class="text-xs text-wrap">{{ pin_notice?.user?.name }}</td>
                    <td v-if="selected_filter != ProjectFilters.FILTER_MY" class="text-xs text-wrap">{{
                      pin_notice?.company?.name }}</td>

                    <td>
                      <el-tooltip :enterable="false" placement="top-start">
                        <template #content>
                          <span>View PIN</span>
                        </template>

                        <el-button class="mb-1" :disabled=disable_state
                          v-if="pin_notice.status == PinNoticeStatuses.ACTIVE || pin_notice.status == PinNoticeStatuses.ARCHIVED"
                          type="success" size="small" @click="viewPin(pin_notice)">
                          <i v-if="disable_count == 0" class="fa fa-eye"></i>{{ disable_count > 0 ? disable_count : ""
                          }}</el-button>
                      </el-tooltip>

                      <el-tooltip :enterable="false" placement="top-start"
                          v-if="($store.state.isUserABuyer() || $store.state.isUserAnOriginator())">
                          <template #content>
                            <span>Contracting Authorities</span>
                          </template>

                          <el-button class="mb-1 me-1" plain type="success" size="small" @click="openContractingAuthorityList(pin_notice)">
                            <i class="fa fa-shield"></i></el-button>
                        </el-tooltip>

                      <el-button-group v-if="$store.state.isAdmin() || (selected_filter == ProjectFilters.FILTER_ALL &&
    (
      pin_notice?.user?.id != user.id &&
      user?.company?.id != pin_notice?.company?.id &&
      !company_ids.includes(pin_notice?.company?.id)
    ))">
                        <el-tooltip :enterable="false" placement="top-start">
                          <template #content>
                            <span>Request to join as additional contracting authority</span>
                          </template>

                          <el-button class="mb-1" v-if="($store.state.isUserABuyer() || $store.state.isUserAnOriginator()) && !pin_notice.is_user_a_contracting_authority &&
    pin_notice.status == PinNoticeStatuses.ACTIVE &&
    pin_notice.is_deadline_passed == 0 &&
    (pin_notice.project_status != ProcurementProjectStatuses.CODE_DOC_FINALIZE && pin_notice.project_status != ProcurementProjectStatuses.CODE_ITT_PENDING)
    " type="primary" size="small" @click="requestContractingAuthority(pin_notice)">
                            <i class="fa fa-shield"></i>&nbsp;Contracting Authority Request</el-button>
                        </el-tooltip>

                        <el-tooltip :enterable="false" placement="top-start">
                          <template #content>
                            <span>Join PIN</span>
                          </template>

                          <el-button class="mb-1" v-if="!pin_notice.if_user_joined &&
    pin_notice.status == PinNoticeStatuses.ACTIVE &&
    pin_notice.is_deadline_passed == 0 &&
    (pin_notice.project_status != ProcurementProjectStatuses.CODE_DOC_FINALIZE && pin_notice.project_status != ProcurementProjectStatuses.CODE_ITT_PENDING)
    " type="primary" size="small" @click="joinPin(pin_notice)">
                            <i class="fa fa-door-open"></i>&nbsp;Join PIN</el-button>
                        </el-tooltip>

                        <el-tooltip :enterable="false" placement="top-start">
                          <template #content>
                            <span>Leave PIN</span>
                          </template>

                          <el-button class="mb-1" v-if="(pin_notice.if_user_joined && pin_notice.if_user_joined.pin_leave_request == 0) &&
    pin_notice.status == PinNoticeStatuses.ACTIVE &&
    pin_notice.is_deadline_passed == 0 &&
    (pin_notice.project_status != ProcurementProjectStatuses.CODE_DOC_FINALIZE && pin_notice.project_status != ProcurementProjectStatuses.CODE_ITT_PENDING)
    " type="danger" size="small" @click="openLeavePinModal(pin_notice)">
                            <i class="fa fa-door-closed"></i>&nbsp;Leave PIN</el-button>
                        </el-tooltip>
                      </el-button-group>

                      <el-button-group v-if="$store.state.isAdmin() || (
      (
        pin_notice?.user?.id == user.id ||
        user?.company?.id == pin_notice?.company?.id ||
        company_ids.includes(pin_notice?.company?.id)
      ))
    ">

                        <el-tooltip :enterable="false" placement="top-start"
                          v-if="($store.state.isUserABuyer() || $store.state.isUserAnOriginator()) && pin_notice.project_status == 'DOC'">
                          <template #content>
                            <span>Threshold value setting</span>
                          </template>

                          <el-button plain type="warning" size="small" @click="openSetting(pin_notice)">
                            <i class="fa fa-cog"></i></el-button>
                        </el-tooltip>

                        <el-tooltip :enterable="false" placement="top-start"
                          v-if="($store.state.isUserABuyer() || $store.state.isUserAnOriginator()) && pin_notice.project_status == 'DOC'">
                          <template #content>
                            <span>Award Criteria</span>
                          </template>

                          <el-button plain type="warning" size="small" @click="openCriteriaSetting(pin_notice)">
                            <i class="fa fa-percent"></i></el-button>
                        </el-tooltip>

                        <el-tooltip :enterable="false" placement="top-start">
                          <template #content>
                            <span>Edit Details</span>
                          </template>

                          <el-button class="mb-1"
                            v-if="$store.state.isUserABuyer() || $store.state.isUserAnOriginator()" type="primary"
                            size="small" @click="openPinForm(pin_notice)">
                            <i class="fa fa-edit"></i></el-button>
                        </el-tooltip>

                        <el-tooltip :enterable="false" placement="top-start">
                          <template #content>
                            <span>View PIN Participants</span>
                          </template>

                          <el-button class="mb-1"
                            v-if="$store.state.isUserABuyer() || $store.state.isUserAnOriginator()" type="info"
                            size="small" @click="viewParticipants(pin_notice.id, 'pin')">
                            <i class="fa fa-list"></i>&nbsp;({{ pin_notice.total_pin_participants}})</el-button>
                        </el-tooltip>

                        <el-tooltip :enterable="false" placement="top-start">
                          <template #content>
                            <span>View History</span>
                          </template>

                          <el-button class="mb-1"
                            v-if="$store.state.isUserABuyer() || $store.state.isUserAnOriginator()" type="warning"
                            size="small" @click="viewProjectHistory(pin_notice)">
                            <i class="fa fa-history"></i></el-button>
                        </el-tooltip>

                        <el-button class="mb-1"
                          v-if="pin_notice.project_status == ProcurementProjectStatuses.CODE_PENDING && ($store.state.isUserABuyer() || $store.state.isUserAnOriginator())"
                          type="primary" size="small" @click="sendPublishRequest(pin_notice)">
                          <i class="fa fa-upload"></i>&nbsp;Publish PIN Notice</el-button>

                        <el-button class="mb-1"
                          v-if="pin_notice.project_status == ProcurementProjectStatuses.CODE_DOC_FINALIZE && $store.state.isUserAnOriginator()"
                          type="danger" size="small" @click="initiatePin(pin_notice)">
                          <i class="fa fa-upload"></i>&nbsp;Initiate ITT</el-button>

                      </el-button-group>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-footer pt-0">
            <pagination :meta="meta" v-on:update="loadPinNotices('list')" />
          </div>
        </div>
      </div>
    </div>

  </div>

  <el-dialog id="pin-modal" v-model="pin_modal" width="70%">
    <PinNoticeForm @close="afterSave" @cancel="pin_modal = false" :key="'pin_form_' + pin_notice.id"
      :pin_notice="pin_notice"></PinNoticeForm>
  </el-dialog>

  <el-drawer v-model="showForm" :show-close="true" size="80%" direction="rtl" :destroy-on-close="true"
    :append-to-body="true">
    <div v-if="selected" class="container">
      <PinNoticeView :view_type="'PIN'" :view_itt="1" :selected_framework="selected" @close="loadPinNotices('list')">
      </PinNoticeView>
    </div>
  </el-drawer>

  <el-drawer v-model="history_drawer" :show-close="true" size="40%" direction="rtl" :destroy-on-close="true"
    :append-to-body="true">
    <div v-if="selected" class="container">
      <ProcurementProjectHistory :procurement_project_id="selected.procurement_project_id" :name="selected.name">
      </ProcurementProjectHistory>
    </div>
  </el-drawer>

  <el-drawer v-model="participant_drawer" :show-close="true" size="40%" direction="rtl" :destroy-on-close="true"
    :append-to-body="true">
    <div class="container">
      <ParticipantsList :id="participant_form.id" :type="participant_form.type"></ParticipantsList>
    </div>
  </el-drawer>

  <el-drawer v-model="show_setting" :show-close="true" size="80%" direction="rtl" :destroy-on-close="true"
    :append-to-body="true">
    <div class="container">
      <pin-lot-threshold-setting :pin_notice="selected"></pin-lot-threshold-setting>
    </div>
  </el-drawer>

  <el-dialog id="modal-leave" v-model="leave_modal" title="Leave this PIN?" width="500">
    <div v-if="selected_pin">
      You are about to leave the pin for the project: {{ selected_pin.name }}, Proceed?
      <br>
      <label class="h6 mt-3">Reason:</label>
      <el-input autosize type="textarea" class="mt-1" v-model="leave_reason" placeholder="Reason here..." />
    </div>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="leave_modal = false">Cancel</el-button>
        <el-button type="danger" @click="leavePin(selected_pin)">
          Confirm
        </el-button>
      </div>
    </template>
  </el-dialog>

  <el-dialog destroy-on-close id="modal-invite" v-model="invite_modal" title="Invite Participants to join this PIN?"
    width="60%">
    <div class="form-group">
      <label class="form-label">Select Active Pin Notices</label>
      <el-select @change="pinSelectChange('procurement_project_participants')" placeholder="Select or search a PIN" filterable
        v-model="selected_pin_invitee" value-key="name" class="">
        <el-option v-for="pin_notice,i in pin_notices_for_invitee" :value="pin_notice" :label="pin_notice.name"
          :key="i" />
      </el-select>
    </div>

    <TransferComponent v-if="invite_participant_type == 0" :key="'invite_component_' + modal_invite_count"
      :filter_placeholder="'Search...'" :transfer_data="buyers" :left_title="'Users who aren\'t invited yet'"
      :right_title="'Selected Users for Invitation'" :right_button_text="'Invite'"
      @update_value="getSelectedParticipants" @handle_click="inviteParticipants"></TransferComponent>

    <MultipleEmailsComponent v-if="invite_participant_type == 1 && selected_pin_invitee" v-model="selected_buyer_ids"
      :email_data="null" :button_size="'default'" :add_text="'Add External User Email'" :tag_size="'large'"
      @update="getSelectedExternalUser"></MultipleEmailsComponent>



    <template #footer>
      <div class="dialog-footer">
        <el-button @click="invite_modal = false">Cancel</el-button>
        <el-button v-if="invite_participant_type == 1" :disabled="!selected_buyer_ids" type="primary"
          @click="inviteParticipants"><i class="fa fa-save"></i>&nbsp;Invite</el-button>
      </div>
    </template>
  </el-dialog>

  <el-drawer v-model="show_criteria_setting" :show-close="true" size="30%" direction="rtl" :destroy-on-close="true"
    :before-close="handleClose" :append-to-body="true">
    <div class="container">
      <pin-product-weighting-form :weighting="selected.pin_product_weightings" :pin_notice="selected"
        @close="loadPinNotices('list')"></pin-product-weighting-form>
    </div>
  </el-drawer>

  <el-dialog destroy-on-close id="modal-contract-authority" v-model="contract_authority_modal" title="Contracting Authority"
    width="60%">

    <el-tabs v-model="contract_authority_tab" type="border-card" class="demo-tabs">
      <el-tab-pane :name="'list_tab'">
        <template #label>
          <span class="custom-tabs-label">
            <i class="fa fa-list"></i>
            <span>&nbsp;{{ (invite_tab_enable ? "Pending Additional" : "") }} Contracting Authorities List</span>
          </span>
        </template>
        
        <div class="card my-3">
          <div class="card-body px-0 pb-2">
            <div class="table-responsive">
              <table class="table table-fluid align-items-center mb-0">
                <thead>
                  <tr>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Name</th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Company</th>
                    <th v-if="!invite_tab_enable" class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Type</th>
                    <th v-if="invite_tab_enable" class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Project/PIN Name</th>
                    <th v-if="invite_tab_enable" class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Request Date</th>
                    <th v-if="invite_tab_enable" class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="selected_pin_for_contracting_authority">
                    <td>{{ selected_pin_for_contracting_authority?.user?.name }}</td>
                    <td>{{ selected_pin_for_contracting_authority?.user?.company?.name }}</td>
                    <td v-if="!invite_tab_enable">Main Contracting Authority</td>
                  </tr>
                  <tr v-if="!contracting_authorities_list.length">
                    <td v-if="isLoading" colspan="100%" class="text-sm">
                      <i class="fas fa-cog fa-spin me-2"></i>Loading...
                    </td>
                  </tr>
                  <tr v-else class="text-sm" v-for="authority in contracting_authorities_list" :key="authority.id">
                    <td>{{ authority.user }}</td>
                    <td>{{ authority.company }}</td>
                    <td v-if="!invite_tab_enable">Additional Contracting Authority</td>
                    <td v-if="invite_tab_enable">{{ authority.project }}</td>
                    <td v-if="invite_tab_enable">{{ $filters.datetimeFormatFilter(authority.created_at) }}</td>
                    <td v-if="invite_tab_enable">
                      <el-button size="small" type="success" class="me-3" @click="updateRequest(authority, 1)" v-tooltip="'Edit'"
                        v-allowed="'additional_contracting_authority,update'">
                        <i class="fas fa-check "></i>Approve
                      </el-button>
                      <el-button size="small" type="danger" @click="updateRequest(authority, 0)" v-tooltip="'Delete'"
                        v-allowed="'additional_contracting_authority,update'">
                        <i class="fas fa-trash "></i>Reject
                      </el-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="card-footer pt-0">
              <pagination :meta="additional_contracting_meta" v-on:update="contracingAuthorities(current_contracting_status, current_contracting_pin_id)" />
            </div>
          </div>
        </div>

      </el-tab-pane>
      <el-tab-pane v-if="invite_tab_enable" :name="'invite_tab'">
        <template #label>
          <span class="custom-tabs-label">
            <i class="fa fa-plus"></i>
            <span>&nbsp;Invite Additional Contracting Authorities</span>
          </span>
        </template>
      
        <div class="form-group">
          <label class="form-label">Invitation Type</label>
          <el-select v-model="contract_authority_participant_type" class="">
            <el-option key="0" label="Internal User" :value="0" />
            <el-option key="1" label="External User" :value="1" />
          </el-select>
        </div>

        <div class="form-group">
          <label class="form-label">Select Active Pin Notices</label>
          <el-select @change="pinSelectChange('additional_contracting_authorities')" placeholder="Select or search a PIN" filterable
            v-model="selected_pin_invitee" value-key="name" class="">
            <el-option v-for="pin_notice,i in pin_notices_for_invitee" :value="pin_notice" :label="pin_notice.name"
              :key="i" />
          </el-select>
        </div>

        <TransferComponent v-if="contract_authority_participant_type == 0" :key="'invite_component_' + modal_invite_count"
          :filter_placeholder="'Search...'" :transfer_data="buyers" :left_title="'Buyers/Originators not yet invited'"
          :right_title="'Selected for Invitation'" :right_button_text="'Invite'"
          @update_value="contractAuthorityInvitee" @handle_click="inviteAdditionalContractingAuthorities"></TransferComponent>

        <MultipleEmailsComponent v-if="contract_authority_participant_type == 1 && selected_pin_invitee" v-model="contract_authority_invitee"
          :email_data="null" :button_size="'default'" :add_text="'Add External User Email'" :tag_size="'large'"
          @update="contractAuthorityExternalUsers"></MultipleEmailsComponent>
      
      </el-tab-pane>
    </el-tabs>

    <template #footer>
      <div class="dialog-footer">
        <el-button @click="contract_authority_modal = false">Cancel</el-button>
        <el-button v-if="contract_authority_participant_type == 1 && contract_authority_tab == 'invite_tab'" :disabled="!contract_authority_invitee" type="primary"
          @click="inviteAdditionalContractingAuthorities"><i class="fa fa-save"></i>&nbsp;Invite</el-button>
      </div>
    </template>
  </el-dialog>

</template>

<script>
import {
  PinNotices
} from '@/api_services/pin_notices'
import {
  ProcurementProjects
} from '@/api_services/procurement_projects'
import PinNoticeForm from './PinNoticeForm'
import {
  mapState
} from 'vuex'
import PinNoticeView from './PinNoticeView'
import Swal from 'sweetalert2';
import {
  IttFramework
} from '@/api_services/itt_framework'
import LoadingComponent from '@/components/common/LoadingComponent.vue'
import ProcurementProjectHistory from '../procurement_projects/ProcurementProjectHistory.vue'
import $ from 'jquery'
import ParticipantsList from '../procurement_projects/ParticipantsList.vue'
import {
  ProcurementProjectParticipants
} from '@/api_services/procurement_project_participants'
import PinLotThresholdSetting from './PinLotThresholdSetting.vue'
import TransferComponent from '@/components/common/TransferComponent.vue'
import { User } from '@/api_services/user'
import MultipleEmailsComponent from '@/components/common/MultipleEmailsComponent.vue'
import PinProductWeightingForm from './PinProductWeightingForm.vue'
import {
  AdditionalContractingAuthority
} from '@/api_services/additional_contracting_authority'

export default {
  computed: {
    ...mapState(['ProcurementProjectStatuses', 'PinNoticeStatuses', 'ProjectFilters', 'BuyerRoleName']),
    user() {
      return this.$store.state.savedUser // get state
    }
  },
  components: {
    PinNoticeForm,
    PinNoticeView,
    LoadingComponent,
    ProcurementProjectHistory,
    ParticipantsList,
    PinLotThresholdSetting,
    TransferComponent,
    MultipleEmailsComponent,
    PinProductWeightingForm
  },
  data() {
    return {
      selected_pin_for_contracting_authority: null,
      current_contracting_pin_id: null,
      current_contracting_status: 0,
      invite_tab_enable: true,
      contracting_authorities_list: [],
      contract_authority_tab: 'list_tab',
      contract_authority_invitee: null,
      contract_authority_participant_type: 0,
      contract_authority_modal: false,
      pending_contract_authority_count: 0,
      invite_participant_type: 0,
      pin_notices_for_invitee: [],
      selected_pin_invitee: null,
      selected_buyer_ids: null,
      modal_invite_count: 0,
      buyers: [],
      invite_modal: false,
      show_setting: false,
      selected_filter: "all",
      filter_options: [{
          label: 'All Projects',
          value: 'all'
        },
        {
          label: 'My Company(s) Projects',
          value: 'company'
        },
        {
          label: 'My Projects',
          value: 'my'
        },
      ],
      company_ids: [],
      leave_reason: null,
      selected_pin: null,
      leave_modal: false,
      participant_form: {
        id: null,
        type: null
      },
      participant_drawer: false,
      form: null,
      history_drawer: false,
      loading_title_message: null,
      loading_content_message: null,
      itt_form: {
        name: null,
        main_description: null,
        common_description: null,
        my_files_description: null,
        qa_description: null,
        my_questions_description: null,
        spec_description: null,
        status: 1,
        pin_notice_id: null,
        procurement_project_id: null,
        if_pin_notice: 1
      },
      pin_modal: false,
      selected: null,
      add_modal: false,
      dialogVisible: false,
      pin_notices: [],
      pin_notice: null,
      to_remove: null,
      meta: {
        page: 1,
        total: 0,
        pages: 1
      },
      additional_contracting_meta: {
        page: 1,
        total: 0,
        pages: 1
      },
      showForm: false,
      loading: false,
      search_value: null,
      disable_count: 0,
      disable_state: false,
      join_form: {
        participation_type: null,
        procurement_project_id: null,
        leave_request_type: null,
        reason: null,
        if_admin_rejected: null,
        if_leave_immediately: null
      },
      show_criteria_setting: false,
      updateRequestForm: {
        status_message: null,
        status: null,
        id: null
      }
    }
  },
  watch: {
    search_value: function () {
      if (!this.search_value || this.search_value.length > 2) this.loadPinNotices('list')
    }
  },
  created: function () {
    if(this.$route.params.activation_code){
      this.activateContractingAuthority(this.$route.params.activation_code)
    }

    this.loadPinNotices('list')

    for (const [key, value] of Object.entries(this.user.companies)) {
      this.company_ids.push(value.id)
    }

    this.loadPinNotices('select')
  },
  methods: {
    openContractingAuthorityList(pin_notice){
      this.selected_pin_for_contracting_authority = pin_notice
      this.invite_tab_enable = false
      this.current_contracting_status = 1
      this.contracingAuthorities(this.current_contracting_status, pin_notice.id)
      this.contract_authority_tab = "list_tab"
      this.contract_authority_modal = true
    },
    async updateRequest(contracting_authority, status) {
        
        const confirmation = await Swal.fire({
          title: (status == 1 ? "Approve" : "Reject") + " this price contracting authority?",
          input: (status == 1 ? null : "textarea"),
          required: (status == 1 ? "false" : "true"),
          inputLabel: "Rejection Message",
          inputPlaceholder: "Message here...",
          showCloseButton: true,  
          showCancelButton: true,
        });
        if (confirmation.isConfirmed) {
          
            this.loading = true

            this.updateRequestForm.status = status
            this.updateRequestForm.status_message = confirmation.value
            this.updateRequestForm.id = contracting_authority.id

            AdditionalContractingAuthority.updateRequest(this.updateRequestForm)
              .then(result => {
                this.contracting_authorities_list = this.contracting_authorities_list.filter(item => item.id !== contracting_authority.id)
                this.pending_contract_authority_count--
                this.$toast.success("Successfully updated")
              })
              .catch(error => {
                this.$toast.error("Failed to update")
              })
              .finally(() => {
                this.loading = false
              })
        }
    },
    requestContractingAuthority(pin_notice) {
      Swal.fire({
        title: 'Do you want to request a contracting authority?',
        text: 'By clicking continue, you will be requesting contracting authority for this PIN. Do you want to proceed?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Continue',
        cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.isConfirmed) {
          this.globalState.loading = true

          AdditionalContractingAuthority.sendInvitationOrRequest(
              {
                contract_authority_participant_type: 0,
                contract_authority_invitee: [this.user?.id],
                pin_notice_id: pin_notice.id,
                procurement_project_id: pin_notice?.procurement_project_id,
                status: 0,
                request_type: "user_request"
              }
          )
          .then(result => {
              this.$toast.success(result.data.message)
              this.loadPinNotices('list')
            })
            .catch(error => {
              console.log(error)
            })
            .finally(() => {
              this.globalState.loading = false
            })

        }
      });
    },
    contracingAuthorities(status, pin_notice_id){
      this.globalState.loading = true
      this.loading = true

      var pin_ids = this.pin_notices_for_invitee.map(function (value, index, array) {
        return value.id; 
      });

      let param = {
        page: this.additional_contracting_meta.page,
        status: status,
        selected_ids: pin_ids,
        pin_notice_id: pin_notice_id
      }

      AdditionalContractingAuthority.get({
          params: param
        })
        .then(result => {
          if (result.data.contracting_authorities) {
            this.contracting_authorities_list = result.data.contracting_authorities
            if(status == 0){
              this.pending_contract_authority_count = this.contracting_authorities_list.length
            }
          }

        })
        .catch(error => {
          this.$toast.error('An error occured while fetching request')
          console.error('error:', error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    activateContractingAuthority(code){
      this.globalState.loading = true

      this.loading = true
      AdditionalContractingAuthority.activate({ code: code })
      .then(result => {
        if(result.data && result.data.status == 1){
          alert(result.data.message)
        }
      })
      .catch(error => {
        
      })
      .finally(() => {
        this.globalState.loading = false
        this.$router.push({ name: 'pin_notices' })
      })
    },
    inviteAdditionalContractingAuthorities(){
      Swal.fire({
        icon: 'info',
          text:
            'You are about to invite these contracting authorities into this pin, are you sure you are going to proceed?',
          showCancelButton: true,
          confirmButtonText: 'Proceed',
          cancelButtonText: 'Cancel',  
      }).then(result => {
        if (result.isConfirmed) {

          this.globalState.loading = true

            this.loading = true
            AdditionalContractingAuthority.sendInvitationOrRequest(
              {
                contract_authority_participant_type: this.contract_authority_participant_type,
                contract_authority_invitee: this.contract_authority_invitee,
                pin_notice_id: this.selected_pin_invitee?.id,
                procurement_project_id: this.selected_pin_invitee?.procurement_project_id,
                status: 1,
                request_type: "invitation"
              }
            ).then(result => {
                this.$toast.success(result.data.message)
                this.selected_pin_invitee = null
                this.contract_authority_invitee = null
                this.contract_authority_modal = false
              })
              .catch(error => {
                this.$toast.error('An error occured while inviting buyers')
                console.error('error:', error.response.data)
              })
              .finally(() => {
                this.globalState.loading = false
              })
            
          }
      })
    },
    contractAuthorityInvitee(value){
      this.contract_authority_invitee = value
    },
    contractAuthorityExternalUsers(values){
      const array = values.split(",");
      this.contract_authority_invitee = array
    },
    openContractAuthorityModal(type){
      this.selected_pin_for_contracting_authority = null
      this.contract_authority_invitee = null
      this.invite_tab_enable = true

      if(type == 0){
        this.loadParticipants("additional_contracting_authorities")
      }
      this.current_contracting_status = 0
      this.contracingAuthorities(this.current_contracting_status, null)
      this.modal_invite_count++
      this.contract_authority_tab = "list_tab"
      this.contract_authority_participant_type = 0
      this.selected_pin_invitee = null
      this.buyers = []
      this.contract_authority_modal = true
    },
    openCriteriaSetting(pin_notice) {
      this.selected = Object.assign({}, pin_notice)
      this.show_criteria_setting = true
    },
    getSelectedExternalUser(values){
      const array = values.split(",");
      this.selected_buyer_ids = array
    },
    pinSelectChange(source){
      this.loadParticipants(source)
    },
    inviteParticipants(){
      Swal.fire({
        icon: 'info',
          text:
            'You are about to invite these participants into joining this pin, are you sure you are going to proceed?',
          showCancelButton: true,
          confirmButtonText: 'Proceed',
          cancelButtonText: 'Cancel',  
      }).then(result => {
        if (result.isConfirmed) {

          this.globalState.loading = true

            this.loading = true
            ProcurementProjectParticipants.sendPartitipantsAnInvitation(
              {
                  invite_participant_type: this.invite_participant_type,
                  invited_users: this.selected_buyer_ids,
                  project_type: "pin",
                  target_id: this.selected_pin_invitee?.id,
                  procurement_project_id: this.selected_pin_invitee?.procurement_project_id
              }
            ).then(result => {
                this.$toast.success(result.data.message)
                this.selected_pin_invitee = null
                this.selected_buyer_ids = null
                this.invite_modal = false
              })
              .catch(error => {
                this.$toast.error('An error occured while inviting buyers')
                console.error('error:', error.response.data)
              })
              .finally(() => {
                this.globalState.loading = false
              })
            
          }
      })
    },
    getSelectedParticipants(value){
      this.selected_buyer_ids = value
    },
    loadParticipants(source){
      this.buyers = []
      this.globalState.loading = true

      User.getUsersByRoleForProjectInvitation(
        {
          source: source,
          project_type: "pin",
          target_id: this.selected_pin_invitee?.id
        })
        .then(result => {
          if (result.data.users && this.selected_pin_invitee) this.buyers = result.data.users

          if(source == "procurement_project_participants"){
            this.modal_invite_count++
            this.invite_modal = true
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.globalState.loading = false
        })
    },
    openInvite(type){
      this.selected_buyer_ids = null
      this.invite_participant_type = type

      if(type == 0){
        this.loadParticipants("procurement_project_participants")
      }
      else{
        this.modal_invite_count++
        this.invite_modal = true
      }

    },
    filterChange(value) {
      this.loadPinNotices('list')
    },
    openLeavePinModal(pin) {
      this.selected_pin = pin,
        this.leave_modal = true
    },
    leavePin(pin_notice) {
      if (this.leave_reason && this.leave_reason !== "") {
        Swal.fire({
          title: 'Leave this pin?',
          text: 'By clicking continue, you will be leaving this PIN. Do you want to proceed?',
          icon: 'info',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Continue',
          cancelButtonText: 'Cancel',
        }).then((result) => {
          if (result.isConfirmed) {
            this.globalState.loading = true

            this.join_form.participation_type = "pin"
            this.join_form.procurement_project_id = pin_notice.procurement_project.id
            this.join_form.leave_request_type = 0
            this.join_form.reason = this.leave_reason
            this.join_form.if_leave_immediately = 1

            ProcurementProjectParticipants.leaveParticipant(pin_notice.id, this.join_form)
              .then(result => {
                this.leave_reason = null
                this.selected_pin = null,
                  this.leave_modal = false

                this.$toast.success(result.data.message)
                this.loadPinNotices('list')
              })
              .catch(error => {
                console.log(error)
              })
              .finally(() => {
                this.globalState.loading = false
              })

          }
        });
      } else {
        this.$toast.warning("Please enter a reason for leaving.")
      }
    },
    viewParticipants(id, type) {
      if (!id) {
        id = 0
      }
      this.participant_form.id = id
      this.participant_form.type = type
      this.participant_drawer = true
    },
    joinPin(pin_notice) {
      Swal.fire({
        title: 'Do you want to join?',
        text: 'By clicking continue, you will be joining this PIN and cannot be undone. Do you want to proceed?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Continue',
        cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.isConfirmed) {
          this.globalState.loading = true

          this.join_form.participation_type = "pin"
          this.join_form.procurement_project_id = pin_notice.procurement_project.id

          ProcurementProjectParticipants.joinParticipant(pin_notice.id, this.join_form)
            .then(result => {
              this.$toast.success(result.data.message)
              this.loadPinNotices('list')
            })
            .catch(error => {
              console.log(error)
            })
            .finally(() => {
              this.globalState.loading = false
            })

        }
      });
    },
    sendPublishRequest(pin_notice) {
      if (pin_notice.procurement_project) {
        Swal.fire({
          title: 'Submit PIN Publish Request?',
          text: 'Do you want to proceed?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Continue',
          cancelButtonText: 'Cancel',
        }).then((result) => {
          if (result.isConfirmed) {
            this.globalState.loading = true

            this.form = pin_notice.procurement_project
            this.form.status = this.ProcurementProjectStatuses.CODE_SUBMITTED

            ProcurementProjects.update(this.form.id, this.form)
              .then(result => {
                this.$toast.success('Pin request submitted')
                this.loadPinNotices('list')
              })
              .catch(error => {
                console.log(error)
              })
              .finally(() => {
                this.globalState.loading = false
              })

          }
        });
      }
    },
    openSetting(pin_notice) {
      this.selected = Object.assign({}, pin_notice)
      this.show_setting = true
    },
    viewProjectHistory(pin_notice) {
      this.selected = Object.assign({}, pin_notice)
      this.history_drawer = true
    },
    initiatePin(pin_notice) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'Once ITT is initiated, this PIN will be archive. This process is irreversible. Do you want to proceed?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Continue',
        cancelButtonText: 'Return to PIN',
      }).then((result) => {
        if (result.isConfirmed) {

          this.loading_title_message = "Initiating ITT"
          this.loading_content_message = "Please wait......."
          this.globalState.loading = true

          this.itt_form.name = pin_notice.name
          this.itt_form.main_description = pin_notice.main_description
          this.itt_form.common_description = pin_notice.common_description
          this.itt_form.my_files_description = pin_notice.my_files_description
          this.itt_form.qa_description = pin_notice.qa_description
          this.itt_form.my_questions_description = pin_notice.my_questions_description
          this.itt_form.spec_description = pin_notice.spec_description
          this.itt_form.pin_notice_id = pin_notice.id
          this.itt_form.procurement_project_id = pin_notice.procurement_project_id

          IttFramework.create(this.itt_form)
            .then(result => {
              this.$toast.success('New ITT Framework has been created and currently pending')
              this.$router.push({
                path: '/main/itt-frameworks'
              })
            })
            .catch(error => {
              console.log(error)
            })
            .finally(() => {
              this.globalState.loading = false
            })

        }
      });
    },
    buttonCooldown() {
      this.disable_count = this.$button_cooldown
      this.disable_state = true
      let self = this

      var interval = setInterval(function () {
        self.disable_count--

        if (self.disable_count == 0) {
          self.disable_count = 0
          self.disable_state = false
          clearInterval(interval)
        }

      }, 1000)
    },
    viewPin(pin_notice) {

      if (this.$store.state.isUserABuyer() || this.$store.state.isUserAnOriginator()) {
        this.globalState.loading = true

        PinNotices.show(pin_notice.id)
          .then(result => {
            if (result.data.pin_notice) {
              this.selected = Object.assign({}, result.data.pin_notice)
              this.showForm = true

              this.buttonCooldown()
            } else {
              this.$toast.error('No data found')
            }
          })
          .catch(error => {
            this.$toast.error('An error occured while fetching request')
            console.error('error:', error.response.data)
          })
          .finally(() => {
            this.globalState.loading = false
          })
      }

      if (this.$store.state.isUserASupplier()) {
        this.$router.push({
          path: '/main/pin_notices/' + pin_notice.id
        })
      }
    },
    openPinForm(pin_notice) {
      PinNotices.show(pin_notice.id)
        .then(result => {
          if (result.data.pin_notice) {
            this.pin_notice = result.data.pin_notice
            this.pin_notice.name = pin_notice.name
            this.pin_modal = true
          } else {
            this.$toast.error('No data found')
          }
        })
        .catch(error => {
          this.$toast.error('An error occured while fetching request')
          console.error('error:', error.response.data)
        })
    },
    handleClose() {
      this.add_modal = false
      this.show_criteria_setting = false
    },
    afterSave() {
      this.loadPinNotices('list')
      this.pin_modal = false
    },
    search() {
      this.meta.page = 1
      this.loadPinNotices('list')
    },
    remove(bvModalEvt) {
      bvModalEvt.preventDefault()

      this.globalState.loading = true
      /*PinNotices.delete(this.to_remove)
        .then(result => {
          this.$toast.success(result?.data?.message || 'Project has been removed successfully')
          this.loadPinNotices('list')

          this.handleClose()
        })
        .catch(error => {
            this.$toast.error('An error occured while deleting project')
            console.error('error:', error.response.data)

        }).finally(() => {
            this.globalState.loading = false
        })*/
    },
    showRemove(type) {
      this.to_remove = Object.assign({}, type)
      this.dialogVisible = true
    },
    loadPinNotices(type) {
      this.globalState.loading = true
      this.handleClose()
      this.loading = true
      this.pin_notices = []

      let param = {
        page: this.meta.page,
        search_value: this.search_value,
        user_id: this.$store.state.savedUser.id,
        selected_filter: this.selected_filter
      }

      if (type == "select") {
        param.load_all = 1
      }

      PinNotices.get({
          params: param
        })
        .then(result => {
          if (result.data.pin_notices) {
            if (type == "list") {
              this.pin_notices = result.data.pin_notices
              this.meta = result.data.meta
            } else {

              for (var x = 0; x <= result.data.pin_notices.length - 1; x++) {
                var item = result.data.pin_notices[x]
                if (
                  (this.$store.state.isAdmin() || (
                  (
                    item?.user?.id == this.user.id ||
                    this.user?.company?.id == item?.company?.id ||
                    this.company_ids.includes(item?.company?.id)
                  ))) &&
                  item?.project_status == this.ProcurementProjectStatuses.CODE_ACTIVE
                ){
                  this.pin_notices_for_invitee.push(item)
                  this.current_contracting_status = 0
                  this.contracingAuthorities(this.current_contracting_status, null)
                }
              }
            }

          }

        })
        .catch(error => {
          this.$toast.error('An error occured while fetching request')
          console.error('error:', error)
        })
        .finally(() => {
          this.loading = false
        })
      }
    },
  }
</script>
