<template>
  <div class="d-flex align-items-center">
    <div class="me-auto text-xs font-weight-bolder">
      {{ meta.total ? 'Total :' + meta.total : '' }}
    </div>

    <vue-awesome-paginate
      :total-items="meta.total"
      :items-per-page="meta.per_page"
      :max-pages-shown="5"
      v-model="page"
      :on-click="onClickHandler"
      v-if="meta.total"
      paginate-buttons-class="btn btn-xs"
      active-page-class="btn-secondary"
      :show-jump-buttons="true"
      :show-breakpoint-buttons="false"
    />

  </div>
</template>

<script>
export default {
  name: 'PaginateComponent',
  props: ['meta'],
  data() {
    return {
      page: 1,
      perPage: 1
    }
  },
  watch: {
    page: function () {
      this.$parent.meta.page = this.page
      console.log(this.page)
      // props.meta.page = this.page
      // this.$emit('update')
      this.$emit('update', this.page);
    }
  },
  created: function () {
  },
  methods: {
    onClickHandler(page){
      let self = this;
      self.meta.page = this.page
      console.log(page)
      this.$emit('update')
    }
  }
}
</script>

<style scoped>
.pagination-container {
    display: flex;
    column-gap: 10px;
  }
  btn {
    height: 40px;
    width: 40px;
    border: none;
    margin-inline: 5px;
    cursor: pointer;
  }
  .back-btn {
    background-color: red;
  }
  .next-btn {
    background-color: red;
  }
  .btn-active {
    background-color: blue !important;
    color: white;
  }
</style>
