<template>
    <div>
      <h4 class="text-dark">List of Participants</h4>
      <div class="row">
        <div class="col-md-12">
          <div class="card my-3">
            
            <div class="card-body px-0 pb-2">
              <div class="table-responsive">
                <nav class="navbar shadow-none my-3 navbar-transparent float-end">
                  <div class="bg-white border-radius-lg d-flex me-2">
                    <input type="text" name="" id="" class="form-control form-control-sm border-1 ps-3" v-model="search_value"
                      placeholder="Search" />
                  </div>
                </nav>
                <table class="table table-fluid align-items-center mb-0">
                  <thead>
                    <tr class="text-sm">
                      <th>Name</th>
                      <th>Company</th>
                      <th>Date Joined</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="!participants.length">
                      <td v-if="isLoading" colspan="100%" class="text-sm">
                        <i class="fas fa-cog fa-spin me-2"></i>Loading...
                      </td>
                      <td v-else colspan="100%">No participants</td>
                    </tr>
                    <tr v-else class="text-sm" v-for="participant in participants" :key="participant.id">
                      <td>{{ participant?.user.name }}</td>
                      <td>{{ participant?.company.name }}</td>
                      <td>{{ type == "pin" ? $filters.datetimeFormatFilter(participant.pin_join_date) : $filters.datetimeFormatFilter(participant.itt_join_date) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer pt-0">
              <pagination :meta="meta" v-on:update="loadParticipants()" />
            </div>
          </div>
        </div>

      </div>
  
  
    </div>
  </template>
  
  <script>
  import { ProcurementProjectParticipants } from '@/api_services/procurement_project_participants'
  
  
  export default {
    watch: {
      search_value: function () {
        if (!this.search_value || this.search_value.length > 2) this.loadParticipants()
      }
    },
    props: {
        id: Number,
        type: String  
    },
    components: {
      
    },
    
    data() {
      return {
        search_value: null,
        isLoading : true,
        participants: [],
        meta: {
          page: 1,
          total: 0,
          pages: 1
        },
      }
    },
    created: function() {
      this.loadParticipants()
    },
    methods: {
      loadParticipants(){
        this.isLoading = true

        ProcurementProjectParticipants.loadParticipants(this.id, 
        { params: { 
            participation_type: this.type,
            page: this.meta.page,
            search_value: this.search_value
          } 
        }
        )
        .then(result => {
          if (result.data.participants) this.participants = result.data.participants
          this.meta = result.data.meta
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.isLoading = false
        })
      }
    },
    computed: {
  
    }
  }
  </script>
  