<template>
  <div class="card">
    <div class="card-body">
      <div>
        <el-button-group class="mb-3" v-if="isOriginator">
          <el-button size="large" type="primary" class="" @click="meetingModal = true" 
          >Set a meeting</el-button>

          <el-button size="large" type="success" class="" @click="invite_modal = true" 
          ><i class="fa-solid fa-user-plus"></i>&nbsp;Invite External Users</el-button>
        </el-button-group>
      </div>
      <div class="table-responsive">
        <table class="table table-hover table-fluid align-items-center mb-0">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                Organiser
              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                Organisation
              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                Meeting Title
              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                Start Date  
              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                End Date
              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                Actions
              </th>
            </tr>
            <tr v-for="(meeting, index) in meetings" :key="index">
              <td class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                {{ meeting.organizer.name }}
              </td>
              <td class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                {{ meeting.company.name }}
              </td>
              <td class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                {{ meeting.title }}
              </td>
              <td class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                {{ meeting.start_datetime}}  
              </td>
              <td class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                {{ meeting.end_datetime }}
              </td>
              <td class="fit-cell text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                <el-button-group>
                  <el-button @click="openJaasModal(meeting)" size="small" type="success">Join meeting</el-button>
                  <el-button @click="openMeetingModal(meeting)"  size="small" type="primary">Meeting Details</el-button>
                  <el-button v-if="isOrganizer(meeting)" @click="deleteMeeting(meeting.id)"  size="small" type="danger">Cancel/Remove Meeting</el-button>
                </el-button-group>
              </td>
            </tr>
          </thead>
        </table>
      </div>
    
    </div>
  </div>

  <el-dialog
      v-model="jaasModal" 
      title="Meet"
      width="67%"
      @close="closeJaasModal()"
    >
    <div>
      <jaas-component :key="selected_meeting ? 'jaas_component_' + selected_meeting.id : 'jaas_component_default'" :meeting="selected_meeting"></jaas-component>
    </div>
  </el-dialog>

    <el-dialog
      v-model="meetingModal" 
      :title = "selected_meeting ? 'Meeting Details' : 'Schedule Meet the Buyer Event'"
      width="67%"
      @close="closeMeetingModal()"
    >
      <div>
        <meeting-details :key="selected_meeting ? 'meeting_details_' + selected_meeting.id : 'meeting_details_default'" @close_modal="closeMeetingModal()" @update="getMeetings()" :meeting="selected_meeting" :currentUser="savedUser"></meeting-details>
      </div>
    </el-dialog>

    <el-dialog id="modal-invite" v-model="invite_modal" title="Invite Participants to join this Event?" width="60%">
      
      <div class="form-group">
        <label class="form-label">Select Meeting</label>
          <el-select placeholder="Select or search a meeting" filterable v-model="selected_meeting_for_invite" value-key="title" class="">
            <template v-for="(meeting, index) in meetings">
              <el-option  v-if="isOrganizer(meeting)" :value="meeting" :label="meeting.title"></el-option>
            </template>
        </el-select>       
      </div>

        <MultipleEmailsComponent
              v-if="selected_meeting_for_invite"
              :email_data="null"
              :button_size="'default'"
              :add_text="'Add External User Email'"
              :tag_size="'large'"
              @update="getSelectedExternalUser"
        ></MultipleEmailsComponent>

      <template #footer>
        <div class="dialog-footer">
          <el-button @click="invite_modal = false">Cancel</el-button>
          <el-button :disabled="!external_users" type="primary" @click="inviteExternalUsers"><i class="fa fa-save"></i>&nbsp;Invite</el-button>
        </div>
      </template>
    </el-dialog>

</template>

<script>
  import { mapState } from 'vuex'
  import Modal from '@/components/common/Modal'
  import JaasComponent from '@/components/common/Jaas/jaas.vue'
  import MeetingDetails from '@/components/common/Jaas/jaas_meeting_details.vue'
  import { Jaas } from '@/api_services/jaas'
  import MultipleEmailsComponent from '@/components/common/MultipleEmailsComponent.vue'
  import Swal from 'sweetalert2'

  export default {
    components: {
      Modal,
      JaasComponent, 
      MeetingDetails,
      MultipleEmailsComponent
    },
    props: {

    },
    data() {
      return {
        selected_meeting_for_invite: null,
        invite_modal: false,
        external_users: null,
        attachFileForm: null,
        jaasModal: false,
        meetingModal: false,
        meetings: null,
        selected_meeting: null
      }
    },
    computed: {
      ...mapState(['savedUser']),
      isOriginator() {
        return this.currentRole == "originator"
      },
      currentRole() {
        const roleNames = (this.savedUser.role_names || []).map(r => r.toLowerCase())
        if (roleNames.includes('originator')) {
          return 'originator'
        }
        return null
      }
    },
    created () {
      this.getMeetings()
    },
    methods: {
      inviteExternalUsers(){
        Swal.fire({
          icon: 'info',
            text:
              'You are about to invite these participants into joining this meet a buyer event, are you sure you are going to proceed?',
            showCancelButton: true,
            confirmButtonText: 'Proceed',
            cancelButtonText: 'Cancel',  
        }).then(result => {
            if (result.isConfirmed) {
              
              this.globalState.loading = true

              this.loading = true
              Jaas.inviteExternalUsers(
                this.selected_meeting_for_invite.id,
                {
                    invite_participant_type: this.invite_participant_type,
                    invited_users: this.external_users
                }
              ).then(result => {
                  this.$toast.success(result.data.message)
                  this.selected_meeting_for_invite = null
                  this.external_users = null
                  this.invite_modal = false
                })
                .catch(error => {
                  this.$toast.error('An error occured while inviting external users')
                  console.error('error:', error.response.data)
                })
                .finally(() => {
                  this.globalState.loading = false
                })
              
            }
        })
      },
      getSelectedExternalUser(values){
        const array = values.split(",");
        this.external_users = array
      },
      isOrganizer(meeting) {
        return meeting.organizer_id == this.savedUser.id
      },
      getMeetings() {
        Jaas.get().then(result => {
          this.meetings = [...result.data.invitee_meetings, ...result.data.organizer_meetings]
        })
      },
      openJaasModal(meeting) {
        this.selected_meeting = meeting
        if(this.selected_meeting) this.jaasModal = true;
      },
      closeJaasModal() {
        this.selected_meeting = null;
        this.jaasModal = false
      },
      openMeetingModal(meeting) {
        this.selected_meeting = meeting
        this.meetingModal = true;
      },
      closeMeetingModal() {
        this.selected_meeting = null;
        this.meetingModal = false
      },
      deleteMeeting(id) {
        Jaas.delete(id).then(deleted => {
          this.$toast.success('Selected meeting has been deleted')
          this.getMeetings()
        })
      }
    }
  }

</script>
<style scoped>

</style>