<template>
  <div>
    <el-drawer
      title="Tell us your question"
      v-model="showAdd"
      direction="rtl"
    >
    <question-form :itt_framework_id="itt_framework_id" :pin_notice_id="pin_notice_id" @update="getQuestions()"></question-form>
    </el-drawer>

    <div style="text-align: left;" class="row" v-if="itt_framework">
      <div class="col-md-12">
        <div class="card my-3">
          <div class="card-body px-0 pb-2">
            <div class="px-4">
              <h4>My Questions</h4>
              <div v-html="itt_framework.my_questions_description"></div>
              <!-- <span class="text-wrap">Here is the where you can submit a question to the CPRAS Framework Mangers. You will be notified of their answer via email. All questions are private by default; however, the answer may be posted publicly if it is believed to be in the public interest. In this case, the question will be anonymised.</span> -->
            </div>
            <div class="row">
              <!-- <div class="col-md-3">
                
              </div> -->
              <div class="col-md-12">
                <nav
                  class="navbar shadow-none my-3 navbar-transparent float-end"
                >
                  <el-form
                    :inline="true"
                    class="demo-form-inline"
                    @submit.native.prevent="getQuestions($event)"
                  >
                    <!-- <el-form-item>
                      <el-input
                        v-model="term"
                        placeholder="Search File"
                        size="small"
                      ></el-input>
                    </el-form-item>
                    <el-form-item>
                      <el-button
                        type="primary"
                        @click="getQuestions()"
                        size="small"
                        >Search</el-button
                      >
                    </el-form-item> -->
                    <el-form-item>
                      <el-button
                        type="primary"
                        @click="create()"
                        size="small"
                        v-allowed="'questions, create'"
                        v-if="
                            
                              (itt_framework && 
                              !itt_framework.is_deadline_passed && 
                              !itt_framework.is_archived && 
                              $store.state.isUserASupplier())
                          "
                        >Ask a question</el-button
                      >
                    </el-form-item>
                  </el-form>
                </nav>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-fluid align-items-center mb-0">
                <thead>
                  <tr>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Questions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="question in questions" :key="question.id">
                    <td class="text-sm">
                      <question-answer-row
                        :question="question"
                        :showUserInfo="true"
                      ></question-answer-row>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-footer pt-0">
            <pagination :meta="meta" v-on:update="getQuestions()" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Question } from '@/api_services/question'
import QuestionForm from './QuestionForm.vue'
import QuestionAnswerRow from './QuestionAnswerRow.vue'
import Swal from 'sweetalert2'

export default {
  components: { QuestionForm, QuestionAnswerRow },
  props: {
    itt_framework: {
      type: Object,
      required: false,
      default: null
    },
    if_pin_notice: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data() {
    return {
      company_ids: [],
      itt_framework_id: null,
      pin_notice_id: null,
      showAdd: false,
      questions: [],
      term: null,
      meta: {
        page: 1,
        total: 0,
        pages: 1
      },
    }
  },
  computed: {
    this_user() {
      return this.$store.state.savedUser 
    }
  },
  created: function() {
    if(this.if_pin_notice == 1){
      this.pin_notice_id = this.itt_framework?.id
    }
    else{
      this.itt_framework_id = this.itt_framework?.id
    }
    this.getQuestions()

    for (const [key, value] of Object.entries(this.this_user.companies)) {
      this.company_ids.push(value.id)
    }
  },
  methods: {
    checkIfNotJoined(){
      if(
        !this.itt_framework.if_user_joined && 
        this.itt_framework?.user?.id != this.this_user.id &&
        this.this_user?.company?.id != this.itt_framework?.company?.id &&
        !this.company_ids.includes(this.itt_framework?.company?.id)
      ){
        return true
      }
      return false
    },
    showFullDetails(question) {

    },
    create() {
      let type = "itt"
      if (this.if_pin_notice == 1) {
        type = "pin"
      }

      if(this.checkIfNotJoined()){
        Swal.fire({
          title: 'Warning!',
          text: "You need to join this " + type + " in order to ask a question!",
          icon: 'warning',
        })
        return false
      }

      this.showAdd = true
    },
    search() {
      this.meta.page = 1
      this.getQuestions()
    },
    getQuestions() {
      this.showAdd = false
      Question.myQuestions({
        params: { 
          page: this.meta.page, 
          common: true, 
          term: this.term, 
          itt_framework_id: this.itt_framework?.id, 
          load_based_company:1,
          if_pin_notice: this.if_pin_notice
        }
      })
        .then(result => {
          if (result.data.questions) this.questions = result.data.questions
          this.meta = result.data.meta
        })
        .catch(error => {
          this.$toast.error('An error occured while fetching questions')
          console.error('error:', error.response.data)
        })
    }
  }
}
</script>

<style scoped>
.completed {
  background: #d3ffe3;
}
</style>
