<template>
  <div class="h-100">
    <div class="loading text-sm text-center" v-if="isLoading">
      loading conversations...
    </div>
    <div v-else class="h-100 d-flex flex-column">
      <div class="general-alert text-sm text-justify px-2">
        Attention: Please note that the CPRAS Framework Managers are actively monitoring this conversation. We kindly remind you to exercise responsibility and professionalism with your messages, keep all discussions within this platform and refrain from sharing any personal or sensitive information. For the purpose of fair competition, please also be mindful that messages may be shared with competing suppliers if they are believed to be of shared interest. Thank you for your cooperation. If you have any questions or concerns, please contact the CPRAS support team.
      </div>
      <div v-if="isReadOnly" class="readonly-alert text-xxs">Read Only</div>
      <div v-else class="conversation-alert text-xxs">Conversation</div>
      <div class="conversation flex-1">
        <div class="d-flex flex-column h-100">
          <div v-for="(reply, index) in replies" :id="index" :key="index" class="reply" :class="[{'mt-auto': !index, 'admin': reply.role == 'admin'}, reply.position]">
            <div class="position-relative">
              <div class="message shadow-sm border border-1 rounded-3 d-inline-block">
                <div class="sender text-xxs mb-1">{{ reply.sender_name }} - {{ reply.role == 'admin' ? 'CPRAS framework admin' : reply.company_name }}</div>
                <div>{{ reply.message }}</div>
                <div v-if="reply.file_name" class="file text-xs position-relative">
                  <a :href="reply.file_download_path" target="_blank" class="stretched-link">
                    <span class="text-xxs"><i class="fa-solid fa-paperclip me-1"></i></span>{{ reply.file_name }}
                  </a>
                </div>
              </div>
              <a @click="shareMessage(reply)" class="pointer position-absolute text-xxs text-secondary rounded-circle shadow-sm btn-share" v-if="currentRole == 'buyer' && reply.role == 'supplier' && reply.sender_id != savedUser.id">
                <i class="fa-solid fa-share"></i>
              </a>
            </div>
            <div class="time mb-3 text-xxs">
              {{ $filters.datetimeFormatFilter(reply.created_at, {hideSameDay: true}) }}
            </div>
          </div>
          <div ref="conversationEnd"></div>
        </div>
      </div>
      <div v-if="hasExtensionRequest && !extensionRequestForm" class="extension-request-extension_status bg-dark text-white">
        <div v-if="isExtensionAlreadyApprovedButNotYours">
          <div class="text-success text-sm">An extension request from another supplier has been <b>APPROVED!</b></div>
          <div class="text-sm mt-1">
            <b>Number of days approved:</b> {{ message.extension_days_approved }} days
          </div>
        </div>
        <div v-else>
          <div v-if="role == 'supplier' || isAdmin" class="extension-request-supplier">
            <div class="text-info text-sm">Your extension request has been sent to the buyer</div>
            <div class="text-sm mt-1">
              <b>Number of days requested:</b> {{ message.extension_days_requested }} days
            </div>
            <div class="text-sm mt-1">
              <b>Reason for request:</b> {{ message.extension_reason_for_request }}
            </div>
            <hr class="my-4"/>
            <div v-if="isExtensionRequested">
              <div class="text-center text-sm">PLEASE WAIT FOR THE REQUEST TO BE APPROVED/REJECTED</div>
              <div class="text-center text-sm">THANK YOU!</div>
            </div>
            <div v-if="isExtensionApproved">
              <div class="text-success text-sm">Buyer Response: <b>APPROVED</b></div>
              <div class="text-sm mt-1">
                <b>Number of days approved:</b> {{ message.extension_days_approved }} days
              </div>
            </div>
            <div v-if="isExtensionRejected">
              <div class="text-danger text-sm">Buyer Response: <b>REJECTED</b></div>
              <div class="text-sm mt-1">
                <b>Reason for rejection:</b> {{ message.extension_reason_for_rejection }}
              </div>
            </div>
          </div>
          <div v-else>
            <div class="text-info text-sm">{{ message.extension_company_name }} wants to request for an extension</div>
            <div class="text-sm mt-1">
              <b>Number of days requested:</b> {{ message.extension_days_requested }} days
            </div>
            <div class="text-sm mt-1 d-flex">
              <b>Reason for request:</b>
              <div class="ms-1">{{ message.extension_reason_for_request }}</div>
            </div>
            <hr class="my-4"/>
            <div v-if="extensionUpdateForm">
              <form @submit.prevent="updateExtensionRequest()">
                <div class="row">
                  <div class="col">
                    <button :disabled="isReadOnly" type="button" :class="`w-100 mb-0 btn btn${isExtensionFormRejected ? '-' : '-outline-'}danger rounded-0`" @click="reject()">REJECT</button>
                  </div>
                  <div class="col">
                    <button :disabled="isReadOnly" type="button" :class="`w-100 mb-0 btn btn${isExtensionFormApproved ? '-' : '-outline-'}success rounded-0`" @click="approve()">APPROVE</button>
                  </div>
                </div>
                <div v-if="isExtensionFormApproved">
                  <div class="d-flex align-items-center mt-4">
                    <label class="form-label text-white mb-0 me-4">Number of days approved</label>
                    <input :disabled="isReadOnly" v-model="extensionUpdateForm.extension_days_approved" min="1" type="number" required class="form-control form-control rounded-0" style="flex: 1;">
                  </div>
                </div>
                <div v-if="isExtensionFormRejected">
                  <div class="mt-3">
                    <label class="form-label text-white">What is your reason for rejection</label>
                    <textarea :disabled="isReadOnly" v-model="extensionUpdateForm.extension_reason_for_rejection" class="form-control rounded-0" rows="3" placeholder="Type a message..." required></textarea>
                  </div>
                </div>
                <div v-if="isExtensionFormRejected || isExtensionFormApproved" class="mt-4 pt-2">
                  <button :disabled="isReadOnly || isSending" type="submit" class="mb-0 w-100 btn btn-lg btn-white rounded-0">{{isSending ? 'Sending...' : 'Submit Response'}}</button>
                </div>
              </form>
            </div>
            <div v-else>
              <div v-if="isExtensionApproved">
                <div class="text-success text-sm">Your Response: <b>APPROVED</b></div>
                <div class="text-sm mt-1">
                  <b>Number of days approved:</b> {{ message.extension_days_approved }} days
                </div>
              </div>
              <div v-if="isExtensionRejected">
                <div class="text-danger text-sm">Your Response: <b>REJECTED</b></div>
                <div class="text-sm mt-1">
                  <b>Reason for rejection:</b> {{ message.extension_reason_for_rejection }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="extensionRequestForm" class="extension-request-form pb-4 bg-dark text-white">
        <form @submit.prevent="sendExtensionRequest()">
          <div>
            <label class="form-label text-white">What is your reason for extension</label>
            <textarea :disabled="isReadOnly" ref="reasonTextarea" v-model="extensionRequestForm.extension_reason_for_request" class="form-control rounded-0" rows="3" placeholder="Type a message..." required></textarea>
          </div>
          <div class="d-flex align-items-center mt-3">
            <label class="form-label text-white mb-0 me-4">Number of days requested</label>
            <input :disabled="isReadOnly" v-model="extensionRequestForm.extension_days_requested" min="1" type="number" required class="form-control form-control rounded-0" style="flex: 1;">
          </div>
          <div class="d-flex flex-row-reverse mt-5">
            <button :disabled="isReadOnly || isSending" type="submit" class="w-70 ms-3 btn btn-lg btn-white rounded-0">{{isSending ? 'Sending...' : 'Submit Request'}}</button>
            <button :disabled="isReadOnly || isSending" type="reset" class="w-30 btn btn-outline-primary rounded-0" @click="hideExtensionRequestForm()">Cancel</button>
          </div>
        </form>
      </div>
      <div v-else-if="attachFileForm" class="attach-file-form pb-4 bg-secondary text-white">
       
        <form @submit.prevent="sendAttachFileForm()">
          <div class="mb-3">
            <input ref="file" class="form-control rounded-1" type="file" required />
          </div>
          <textarea :disabled="isReadOnly" ref="replyTextarea" v-model="attachFileForm.message" class="form-control rounded-0" rows="3" placeholder="Add a message..."></textarea>
          <div v-if="role == 'buyer'" class="form-check mt-3">
            <input ref="sendToAll" v-model="attachFileForm.send_to_all" id="send-to-all-suppliers" class="form-check-input" type="checkbox" />
            <label class="text-white custom-control-label mb-0 ms-0" for="send-to-all-suppliers" style="cursor: pointer;">
              Send to all suppliers
            </label>
          </div>
          <div class="d-flex flex-row-reverse mt-5">
            <button :disabled="isReadOnly || isReplying" type="submit" class="w-70 ms-3 btn btn-lg btn-white rounded-0">{{isReplying ? 'Sending...' : 'Send File'}}</button>
            <button :disabled="isReadOnly || isReplying" type="reset" class="w-30 btn btn-outline-white rounded-0" @click="hideAttachFileForm()">Cancel</button>
          </div>
        </form>
      </div>
      <div v-else class="compose pb-4">
        <form class="d-inline" @submit.prevent="sendReply()">
          <textarea :disabled="isReadOnly" ref="replyTextarea" v-model="replyForm.message" class="form-control rounded-0" rows="3" placeholder="Type a message..."></textarea>
          <div class="d-flex flex-row-reverse mt-3">
            <button :disabled="isReadOnly || isReplying" type="submit" class="ms-2 btn btn-primary rounded-0">{{isReplying ? 'Sending...' : 'Send Message'}}</button>
            <button :disabled="isReadOnly || isReplying" type="button" v-if="!isAdmin" class="ms-2 btn btn-white rounded-0" @click="showAttachFileForm()"><i class="fa-solid fa-paperclip"></i></button>
            <button :disabled="isReadOnly || isReplying" type="button" v-if="!isAdmin && role == 'supplier' && canRequestForExtension" class="ms-2 btn btn-white rounded-0" @click="showExtensionRequestForm()" title="Request for Extension" v-tooltip="'Request for Extension'">
              <i class="fa-regular fa-calendar-plus"></i>
            </button>
            <button @click="modal_export = true" type="button" class="btn btn-white rounded-0" title="Export Conversation" v-tooltip="'Export Conversation'">
              <i class="fas fa-regular fa-file-export"></i>
            </button>
          </div>
        </form>
      </div>
    </div>

    <el-dialog
      v-model="shareMessageModal"
      title="Share supplier message"
      width="50%"
      @close="shareMessageReply = null"
    >
    
    <share-message @close_modal="shareMessageModal = false" :reply="shareMessageReply" :buyer-name="savedUser.name" @update="shareMessageModal = false"></share-message>
    
    </el-dialog>

    <el-dialog id="modal-export" v-model="modal_export" title="Export Conversation" width="500" >
      
        <label>Export As:</label>
        <el-select
          v-model="export_type"
          placeholder="Select Type"
        >
          <el-option
            label="CSV"
            value="csv"
          />
          <el-option
            label="PDF"
            value="pdf"
          />
        </el-select>

        <br><br>
        <label>Date Range <i>(Leave empty to load all messages)</i>:</label><br>
                          <el-date-picker
                              v-model="date_range"
                              type="daterange"
                              start-placeholder="Start date"
                              end-placeholder="End date"
                              @change="dateChange"
                              format="YYYY/MM/DD"
                              value-format="YYYY-MM-DD"
                              class="w-100 mb-3"
                            />

        <template #footer>
          <div class="dialog-footer">
            <el-button @click="modal_export = false">Close</el-button>
            <el-button v-bind:disabled="loading || !export_type" type="primary" @click="exportConversation">
              Export
            </el-button>
          </div>
        </template>
      </el-dialog>

  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { ProductSearchMessageApi } from '@/api_services/product_search_message'
  import Modal from '@/components/common/Modal'
  import ShareMessage from './ShareMessage.vue'
  import { toHandlers } from 'vue'
  import Swal from 'sweetalert2'

  export default {
    components: {
      Modal,
      ShareMessage
    },
    props: {
      productSearchResultId: Number,
      role: String
    },
    data() {
      return {
        date_range: null,
        loading: false,
        modal_export: false,
        export_type: null,
        isActive: true,
        isLoading: true,
        isReplying: false,
        isSending: false,
        message: null,
        replies: [],
        replyForm: {},
        extensionRequestForm: null,
        extensionUpdateForm: null,
        attachFileForm: null,
        pollTimeout: null,
        initialExtensionStatus: null,
        shareMessageModal: false,
        shareMessageReply: null,
      }
    },
    created() {
      this.isActive = true
      this.reloadOnClose = false
      this.replyForm = {}
      if (this.productSearchResultId) {
        this.loadMessageAndReplies(false)
      } else {
        this.message = null
        this.replies = []
      }
      const { id: userId, name, company } = this.savedUser
      this.replyForm = {
        sender_id: userId,
        company_id: company?.id,
        role: this.currentRole
      }
    },
    beforeUnmount() {
      clearTimeout(this.pollTimeout)
      this.isActive = false
      this.$emit('close', this.reloadOnClose)
    },
    methods: {
      exportConversation(){
          Swal.fire({
            icon: 'info',
              text:
                'You are about to export this conversation, are you sure you are going to proceed?',
              showCancelButton: true,
              confirmButtonText: 'Proceed',
              cancelButtonText: 'Cancel',  
          }).then(result => {
              if (result.isConfirmed) {

                this.loading = true

                let api
                if(this.export_type == "csv"){
                  api = ProductSearchMessageApi.exportConversationAsCSV(this.productSearchResultId, this.export_type, { params: { 
                    date_range: this.date_range
                  } })
                }
                else{
                  api = ProductSearchMessageApi.exportConversationAsPDF(this.productSearchResultId, this.export_type, { params: { 
                    date_range: this.date_range
                  } })
                }
                
                api.then(result => {

                    this.$toast.success('Export success!')

                    if(this.export_type == "csv"){
                      const url = result.data
                      const link = document.createElement('a')
                      link.href = url
                      link.target = "_blank"
                      link.click()
                    }
                    else{
                      const url = window.URL.createObjectURL(new Blob([result.data]));
                      const link = document.createElement('a');
                      link.href = url;
                      link.setAttribute('download', 'product_conversation_export.pdf');
                      document.body.appendChild(link);
                      link.click();
                    }
                    
                  })
                  .catch(error => {
                    this.$toast.error('An error occured while exporting companies')
                    console.error('error:', error)
                  })
                  .finally(() => {
                    this.loading = false
                  })
                
              }
          })
        },
      loadMessageAndReplies(isPoll) {
        ProductSearchMessageApi.firstOrCreate({ product_search_result_id: this.productSearchResultId })
          .then(result => {
            this.message = result.data.product_search_message
            this.replies = result.data.product_search_replies.map((r) => ({ ...r, position: this.savedUser.id == r.sender_id ? 'right' : 'left' }))
            if (this.isExtensionRequested && (!isPoll || !this.extensionUpdateForm)) {
              this.extensionUpdateForm = {
                extension_status: 1,
                extension_days_approved: this.message.extension_days_requested,
                extension_reason_for_rejection: null
              }
            }
            if (this.initialExtensionStatus == null) {
              this.initialExtensionStatus = this.message.extension_status
            }
            if (this.initialExtensionStatus != 2 && this.isExtensionApproved) {
              this.reloadOnClose = true
            }
            if (this.isActive) {
              this.pollTimeout = setTimeout(() => this.loadMessageAndReplies(true), 5000)
            }
          })
          .catch(error => {
            console.error('error:', error?.response?.data || error)
          })
          .finally(() => {
            this.isLoading = false
            if (!isPoll) {
              this.scrollToRecent('instant')
            }
          })
      },
      showExtensionRequestForm() {
        const { company } = this.savedUser
        this.extensionRequestForm = { extension_status: 1, extension_company_id: company?.id }
        this.scrollToRecent('smooth')
      },
      hideExtensionRequestForm() {
        this.extensionRequestForm = null
      },
      sendInvite(message) {
        this.replyForm.message = message;
        if(this.event_type == 'call')this.sendReply();
      },
      sendReply() {
        if (!this.replyForm.message) return
        this.isReplying = true
        ProductSearchMessageApi.reply(this.message.id, this.replyForm)
          .then(result => {
            this.replies.push({...result.data.product_search_reply, position: 'right'})
            this.replyForm.message = null
            this.scrollToRecent('smooth')
          })
          .catch(error => {
            console.error('error:', error?.response?.data || error)
          })
          .finally(() => {
            this.isReplying = false
            this.$store.commit('newNotifications', true)
          })
      },
      sendExtensionRequest() {
        this.isSending = true
        ProductSearchMessageApi.update(this.message.id, this.extensionRequestForm)
          .then(result => {
            this.message = result.data.product_search_message
            this.extensionRequestForm = null
            this.scrollToRecent('smooth')
          })
          .catch(error => {
            console.error('error:', error?.response?.data || error)
            if (error?.response?.data?.message) {
              this.$toast.error(error?.response?.data?.message)
            }
            if (error?.response?.data?.product_search_message) {
              this.message = error?.response?.data?.product_search_message
              this.extensionRequestForm = null
              this.scrollToRecent('smooth')
            }
          })
          .finally(() => {
            this.isSending = false
          })
      },
      updateExtensionRequest() {
        this.isSending = true
        if (this.isExtensionFormApproved) {
          this.extensionUpdateForm.extension_reason_for_rejection = null
          this.reloadOnClose = true
        } else if (this.isExtensionFormRejected) {
          this.extensionUpdateForm.extension_days_approved = 0
        }
        ProductSearchMessageApi.update(this.message.id, this.extensionUpdateForm)
          .then(result => {
            this.message = result.data.product_search_message
            this.extensionUpdateForm = null
            this.scrollToRecent('smooth')
          })
          .catch(error => {
            console.error('error:', error?.response?.data || error)
            if (error?.response?.data?.message) {
              this.$toast.error(error?.response?.data?.message)
            }
            if (error?.response?.data?.product_search_message) {
              this.message = error?.response?.data?.product_search_message
              this.extensionUpdateForm = null
              this.scrollToRecent('smooth')
            }
          })
          .finally(() => {
            this.isSending = false
          })
        this.scrollToRecent('smooth')
      },
      showAttachFileForm() {
        this.attachFileForm = { ...this.replyForm, message: '' }
        setTimeout(() => {
          if (this.role == 'buyer') {
            // this.$refs.sendToAll?.click()
          }
          this.$refs.file?.click()
        }, 50)
        this.scrollToRecent('smooth')
      },
      hideAttachFileForm() {
        this.attachFileForm = null
      },
      sendAttachFileForm() {
        const file = this.$refs.file.files[0]
        const formData = new FormData()
        formData.append('file', file)
        formData.append('file_name', file?.name)
        for (const [key, value] of Object.entries(this.attachFileForm)) {
          formData.append(key, value)
        }
        this.isReplying = true
        ProductSearchMessageApi.reply(this.message.id, formData)
          .then(result => {
            this.replies.push({...result.data.product_search_reply, position: 'right'})
            this.hideAttachFileForm()
            this.scrollToRecent('smooth')
          })
          .catch(error => {
            console.error('error:', error?.response?.data || error)
          })
          .finally(() => {
            this.isReplying = false
          })
      },
      scrollToRecent(behavior) {
        setTimeout(() => {
          this.$refs.conversationEnd?.scrollIntoView({ behavior, block: 'end' })
        }, 100)
      },
      approve() {
        this.extensionUpdateForm.extension_status = 2
        this.extension_days_approved = this.message.extension_days_requested
      },
      reject() {
        this.extensionUpdateForm.extension_status = 3
      },
      shareMessage(reply) {
        this.shareMessageReply = reply
        this.shareMessageModal = true
      }
    },
    computed: {
      ...mapState(['savedUser']),
      isAdmin() {
        return this.savedUser?.is_admin
      },
      currentRole() {
        const roleNames = (this.savedUser.role_names || []).map(r => r.toLowerCase())
        if (this.isAdmin) {
          return 'admin'
        } else if (roleNames.includes('supplier')) {
          return 'supplier'
        } else if (roleNames.includes('buyer')) {
          return 'buyer'
        }
        return null
      },
      isReadOnly() {
        return this.message?.product_search_result_batch == 2
      },
      hasExtensionRequest() {
        return this.message?.extension_status != 0
      },
      isExtensionRequested() {
        return this.message?.extension_status == 1
      },
      isExtensionApproved() {
        return this.message?.extension_status == 2
      },
      isExtensionRejected() {
        return this.message?.extension_status == 3
      },
      isExtensionFormApproved() {
        return this.extensionUpdateForm?.extension_status == 2
      },
      isExtensionFormRejected() {
        return this.extensionUpdateForm?.extension_status == 3
      },
      canRequestForExtension() {
        return this.message?.extension_status == 0 || this.isExtensionRejected
      },
      isExtensionAlreadyApprovedButNotYours() {
        return this.isExtensionApproved && !this.message?.is_approved_extension_yours
      },
    },
  }
</script>

<style lang="scss">
  .conversation {
    flex: 1;
    padding: 20px 20px 0;
    overflow-y: auto;
    .reply {
      max-width: 80%;
      &.left {
        align-self: flex-start;
        &.admin .sender {
          color: var(--bs-danger);
        }
      }
      &.right {
        align-self: flex-end;
        text-align: right;
        .message {
          background-color: var(--bs-primary);
          color: #fff;
          text-align: left;
        }
      }
      &:nth-last-child(2) .time {
        margin-bottom: 1.25rem !important;
      }
      .message {
        padding: 0.5rem 0.9rem;
        white-space: pre-wrap;
        word-wrap: break-word;
        font-family: inherit;
        overflow: hidden;
        .file {
          margin: 0.5rem -0.9rem -0.5rem;
          padding: 0.25rem 0.9rem 0.4rem;
          background-color: var(--bs-light);
          background-color: var(--bs-secondary);
          a {
            color: #fff;
          }
        }
      }
      .time {
        padding: 0.2rem 0.3rem 0;
      }
    }
  }
  .extension-request-extension_status {
    padding: 30px 20px;
  }
  .extension-request-form,
  .attach-file-form {
    padding: 20px;
  }
  .compose {
    background-color: #dee7e9;
    padding: 20px;
  }
  .loading {
    padding: 20px;
    height: 75vh;
    padding-top: 25vh;
  }
  .conversation-alert, .readonly-alert {
    text-align: center;
    padding: 3px 0;
    font-weight: bold;
  }
  .general-alert {
    text-align: center;
    padding: 3px 0;
    font-weight: bold;
    // font-style: italic;
    color: var(--bs-dark);
  }

  .conversation-alert {
    background-color: #b0eed3;
    color: var(--bs-success);
  }
  .readonly-alert {
    background-color: #fee6e0;
    color: var(--bs-warning);
  }
  .el-drawer__header {
    margin-bottom: 1rem !important;
  }
  .tooltip.show {
    z-index: 3000 !important;
  }
  .attach-file-form .file-uploads {
    width: 100%;
    line-height: 5rem;
    border-style: dashed;
    border-color: var(--bs-secondary);
  }
  .attach-file-form .file-uploads:hover {
    transform: none;
    opacity: 1;
  }
  .attach-file-form .file-uploads label {
    cursor: pointer;
    margin: 0;
  }
  .attach-file-form ul {
    margin-bottom: 0;
  }
  .attach-file-form ul li {
    margin-top: 0.75rem;
    padding: 0 !important;
  }
  .attach-file-form .btn-outline-dark {
    color: #fff !important;
    border-color: #fff !important;
    border-radius: 0;
  }
  .btn-share {
    top: 5px;
    right: -30px;
    width: 24px;
    line-height: 24px !important;
    text-align: center;
  }
</style>
