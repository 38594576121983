import api from './api'

const API = '/api/procurement_project_participants'

export const ProcurementProjectParticipants = {
  joinParticipant: function(id, params) {
    return api.update(API+'/join_participant/'+id, params)
  },
  loadParticipants: function(id, params) {
    return api.get(API+'/load_participants/'+id, params)
  },
  leaveParticipant: function(id, params) {
    return api.update(API+'/leave_participant/'+id, params)
  },
  loadLeaveRequest: function(params) {
    return api.get(API+'/load_leave_request', params)
  },
  sendPartitipantsAnInvitation: function(params) {
    return api.create(API + "/send_invitation", params)
  },
}
